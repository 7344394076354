import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const iarDisclosureTypes = [
    { value: 'hasregaction', label: 'Regulatory Action' },
    { value: 'hascriminal', label: 'Criminal Record' },
    { value: 'hasbankrupt', label: 'Declared Bankruptcy' },
    { value: 'hasciviljudc', label: 'Civil Judgement' },
    { value: 'hasbond', label: 'Bond' },
    { value: 'hasjudgment', label: 'Judgement' },
    { value: 'hasinvstgn', label: 'Investigation' },
    { value: 'hascustcomp', label: 'Customer Dispute' },
    { value: 'hastermination', label: 'Terminated' },
];

const rrDisclosureTypes = [
    { value: 'hasaccredited', label: 'Accredited Investment Fiduciary' },
    { value: 'hascivilbond', label: 'Bond' },
    { value: 'haschartered', label: 'Chartered Life Underwriter' },
    { value: 'hascivil', label: 'Civil Judgement' },
    { value: 'hascriminal', label: 'Criminal Record' },
    { value: 'hascustomerdispute', label: 'Customer Dispute' },
    { value: 'hasfinancial', label: 'Declared Bankruptcy' },
    { value: 'hasinvestigation', label: 'Investigation' },
    { value: 'hasjudgment', label: 'Judgement' },
    { value: 'hasregulatory', label: 'Regulatory Action' },
    { value: 'hasterminated', label: 'Terminated' },
];

const bdDisclosureTypes = [
    { value: 'hasarbitration', label: 'Arbitration' },
    { value: 'hasbond', label: 'Bond' },
    { value: 'hascivilevent', label: 'Civil Event' },
    { value: 'hascriminal', label: 'Criminal Record' },
    { value: 'hasfinancial', label: 'Financial' },
    { value: 'hasjudgment', label: 'Judgement' },
    { value: 'hasregevent', label: 'Regulatory Event' },
];

export default class DisclosureTypes extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        value: PropTypes.any,
        name: PropTypes.string.isRequired,
        multi: PropTypes.bool.isRequired,
        hideTypes: PropTypes.array,
        formName: PropTypes.any,
    };

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(selectedOptions) {
        let selectedValue = null;
        let selectedLabel = null;
        if (this.props.multi) {
            selectedValue = selectedOptions ? selectedOptions.map(el => el.value) : [];
            selectedLabel = selectedOptions ? selectedOptions.map(el => el.label) : [];
        } else if (selectedOptions) {
            selectedValue = selectedOptions.value;
            selectedLabel = selectedOptions.label;
        }

        this.props.onChange({
            target: {
                name: this.props.name,
                value: selectedValue,
                label: selectedLabel,
            }
        });
    }

    render() {
        let disclosureValues = this.props.formName == 'riaIndivSearch' ? iarDisclosureTypes : rrDisclosureTypes;
        let disclosureTypes = this.props.formName == 'bdFirmSearch' ? bdDisclosureTypes : disclosureValues;
        let selectedTypes = this.props.value
            ? disclosureTypes.filter(row => this.props.value.indexOf(row.value) >= 0)
            : [];
        let displayTypes = this.props.hideTypes
            ? disclosureTypes.filter(row => this.props.hideTypes.indexOf(row.value) < 0)
            : disclosureTypes;

        return <Select
            closeMenuOnSelect={!this.props.multi}
            value={selectedTypes}
            isMulti={this.props.multi}
            options={displayTypes}
            onChange={this.handleChange}
            isClearable={true}
            placeholder={this.props.multi ? 'Select Disclosure Type(s)...' : 'Select Disclosure type...'}
        />;
    }
}
