import React from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Row,
    Label,
} from 'reactstrap';
import {PAGE_RESTRICTING_MESSAGE} from '../redux/layout/constants';
import AsyncSelect from 'react-select/async';
import { IarSearchState } from '../redux/firstClassData/dtos';
import APIClient from '../utils/API';
import AddressSearchFields from './SearchFormCards/AddressSearchFields';
import EmailAddressSelect from './SearchFormCards/EmailAddressSelect';
import FullNameSelect from './SearchFormCards/FullNameSelect';
import BrokerDealerSelect from './SearchFormCards/BrokerSelect';
import ExamCodeSearch from './SearchFormCards/ExamCodeSearch'




export default class RiaFirmSearchForm extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
        onFieldChange: PropTypes.func.isRequired,
        formState: PropTypes.instanceOf(IarSearchState).isRequired,
        formName: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.apiClient = new APIClient();

        this.getTypeaheadOptions = this.getTypeaheadOptions.bind(this);
        this.formatCsv = this.formatCsv.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleIncludeExcludeClick = this.handleIncludeExcludeClick.bind(this);
        this.handleCrdIncludeExcludeClick = this.handleCrdIncludeExcludeClick.bind(this);
    }

    getTypeaheadOptions = (fieldName, inputValue, callback) => {
        this.apiClient.getTypeaheadOptions(fieldName, inputValue)
            .then(response => {
                callback(response.data.results);
            });
    };

    formatCsv(value) {
        if (Array.isArray(value)) {
            return value.join(', ')
        }
        return value;
    }

    formatNames(value) {
        if (Array.isArray(value)) {
            return value.join(', ')
        }
        return value;
    }

    handleSelectChange(fieldName, selectedOptions) {
        let selectedValue = selectedOptions ? selectedOptions.map(el => el.value) : [];

        this.props.onFieldChange({
            target: {
                name: `firm_names.${[0]}.${fieldName}`,
                value: selectedValue,
            }
        });
    }

    handleIncludeExcludeClick() {
        this.props.onFieldChange({
            target: {
                name: `firm_names.${[0]}.exclude`,
                value: !this.props.formState.firm_names[0].exclude,
            }
        });
    }

    handleCrdIncludeExcludeClick() {
        this.props.onFieldChange({
            target: {
                name: `firm_crds_list.${[0]}.exclude`,
                value: !this.props.formState.firm_crds_list[0].exclude,
            }
        });
    }

    render() {
        let formState = this.props.formState;
        let hasPermission = this.props.hasPermission;
        let selectedFirmNames = formState.firm_names[0].names.map(names => {
            return { value: names, label: names };
        });

        return (
            <>
                <Form onSubmit={this.props.onSubmit} className={!hasPermission ? "permission-card-section restricted" : ""}>
                    <Card>
                        <CardBody>
                            <h4 className="header-title">IAR Details</h4>

                            <Row className="mb-2">
                                <Col lg={12}>
                                    <Row>
                                        <Col lg={12}>
                                            <FormGroup>
                                                <Input type="text" name="crds" value={this.formatCsv(formState.crds)}
                                                    onChange={this.props.onFieldChange} placeholder="CRD(s)" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <FormGroup>
                                                <Input type="text" name="first_name" value={this.formatNames(formState.first_name)}
                                                    onChange={this.props.onFieldChange} placeholder="First Name" />
                                            </FormGroup>
                                        </Col>
                                        <Col lg={6}>
                                            <FormGroup>
                                                <Input type="text" name="last_name" value={this.formatNames(formState.last_name)}
                                                    onChange={this.props.onFieldChange} placeholder="Last Name" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="pt-2">
                                        <FullNameSelect formName={this.props.formName} />
                                    </Row>
                                    <Row className="pt-2">
                                        <EmailAddressSelect formName={this.props.formName} />
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <FormGroup>
                                                <Card className="my-1 license-groups-card-border">
                                                    <CardHeader className="bg-light">
                                                        <a className="link" onClick={this.handleIncludeExcludeClick}>{formState.firm_names[0].exclude ? 'Exclude' : 'Include'}</a>
                                                        &nbsp;records matching these firms:
                                                    </CardHeader>
                                                    <CardBody className="pt-1">
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label>Firm name(s)</Label>
                                                                    <AsyncSelect
                                                                        placeholder="Select Firm(s)"
                                                                        isMulti
                                                                        value={selectedFirmNames || null}
                                                                        isClearable
                                                                        defaultOptions
                                                                        cacheOptions
                                                                        loadOptions={(inputValue, callback) => this.getTypeaheadOptions('ria_firm', inputValue, callback)}
                                                                        onChange={value => this.handleSelectChange('names', value)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <FormGroup>
                                            <BrokerDealerSelect formState={formState} onChange={this.props.onFieldChange} formName={this.props.formName} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <FormGroup>
                                            <ExamCodeSearch formState={formState} onChange={this.props.onFieldChange} formName={this.props.formName} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <FormGroup>
                                                <Card className="my-1 license-groups-card-border">
                                                    <CardHeader className="bg-light">
                                                        <a className="link" onClick={this.handleCrdIncludeExcludeClick}>{formState.firm_crds_list[0].exclude ? 'Exclude' : 'Include'}</a>
                                                        &nbsp;records matching these CRD(s):
                                                    </CardHeader>
                                                    <CardBody className="pt-1">
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label>Firm CRD(s)</Label>
                                                                    <Input type="text" name="firm_crds" value={this.formatCsv(formState.firm_crds)}
                                                                        onChange={this.props.onFieldChange} placeholder="Select CRD(s)" />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <AddressSearchFields formState={formState} onChange={this.props.onFieldChange} showAddressTypeCheckboxes={false} formName={this.props.formName} showLicenseDataAndEmails={true} />

                        </CardBody>
                    </Card>
                </Form>
                {!hasPermission ?
                    <div className="permission-card">
                        <div className='card permission'>
                            <h6 className="permission-header">{PAGE_RESTRICTING_MESSAGE}</h6>
                        </div>
                    </div> : ""
                }
            </>
        );
    }
}
