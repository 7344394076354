// @flow
import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { mergeSearch } from '../../redux/actions';
import TitleTypes from '../ExecutivesTitles';
import PropTypes from 'prop-types';
import Select from 'react-select';

export default class BrokerDealerSelect extends React.Component {
        static propTypes = {
        onChange: PropTypes.func.isRequired,
        formState: PropTypes.object.isRequired,
        index: PropTypes.number.isRequired,
    };
    constructor(props) {
        super(props);

        this.onFieldChange = this.onFieldChange.bind(this);
        this.options = [
             { value: '', label: '---' },
            { value: 'true', label: 'Include Broker Dealers' },
            { value: 'false', label: 'Exclude Broker Dealers' }

            ]
    }
    onFieldChange(event) {
        this.props.onChange({
            target: {
                name: 'otheractivities_brokerdealer',
                value: event.value,
            }
        });
    }
    render() {
        return (
        <Col lg={13}>
                <Card className="my-1 license-groups-card-border">
                    <CardHeader className="bg-light">
                        &nbsp;Search records based on broker dealers:
                    </CardHeader>
                    <CardBody className="pt-1">
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Broker Dealers</Label>
                                    <Select
                                        options={this.options}
                                        isSearchable={true}
                                        isClearable={true}
                                        autoFocus={false}
                                         onChange={this.onFieldChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

        </Col>
        )

};
}

