// @flow
import React from 'react';
import {Col, FormGroup, Label, Row} from 'reactstrap';
import PropTypes from 'prop-types';
import {RangeSlider} from 'rsuite';
import { loggedInUserHasPermission } from '../helpers/authUtils';


export default class YearOfBirthRange extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        formState: PropTypes.object.isRequired,
    };

    // componentDidMount() {
    //     // Set default value for dob to true
    //     this.props.onChange({
    //         target: {
    //             name: 'dob',
    //             value: true, // Default value for dob
    //         },
    //     });
    // }

    handleSliderChange(name, value) {
        this.props.onChange({
            target: {
                name: name,
                value: value,
            }
        });
    }

    rangeTicks(mark) {
        if (mark % 20 === 0) {
            return <span>{mark}</span>;
        }
        return null;
    }

    render() {
        let formState = this.props.formState;
        const hasConsumerDataSearchPermission = loggedInUserHasPermission('search_consumerdata');

        return (
            <FormGroup>
                <Label>Year of Birth</Label>
                <Row>
                    <Col xs={1}>
                        <input type="checkbox" name="dob"
                               checked={formState.dob}
                               onChange={this.props.onChange} />
                    </Col>
                    <Col>
                        <RangeSlider min={1900} max={2024} disabled={!formState.dob || !hasConsumerDataSearchPermission}
                                     value={formState.dob_range}
                                     onChange={value => this.handleSliderChange('dob_range', value)}
                                     renderMark={this.rangeTicks}
                                     graduated={true}
                        />
                    </Col>
                </Row>
            </FormGroup>
        );
    }
}
