// @flow
import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row} from 'reactstrap';
import { mergeSearch } from '../../redux/actions';
import TitleTypes from '../ExecutivesTitles';

const IndividualTitleSelect = ({ formName }) => {
    const dispatch = useDispatch();
    const titles = useSelector((state) => state.FirstClassData.formStates[formName].formState.executive_titles);
    const search_executive_bool = useSelector((state) => state.FirstClassData.formStates[formName].formState.search_executive);
    const [executive_titles, canAddAnotherTitleClause] = useMemo(() => {
        const canAddAnotherTitleClause = titles.filter(row => {
                    return row.titles.length === 0;
                }).length === 0;
        return [titles, canAddAnotherTitleClause];
    }, [titles]);
    let executive_titles_status = false
    if(titles.length){
        titles.map((object)=>{
            if( object['titles'].length){
                console.log(object['titles'])
                object['titles'].map((innerObject)=>{
                    if(innerObject.length>1){
                    executive_titles_status = true
                    }
                })
            }
        })
    }
    // Added a function for mapping the search_executive based on the executive_titles search
    if(executive_titles_status != search_executive_bool){
        dispatch(mergeSearch({search_executive : executive_titles_status}, formName));
    }


    const onFieldChange = (event, index) => {
        let TitleTypes = executive_titles.slice();
        TitleTypes[index][event.target.name] = event.target.value;
        dispatch(mergeSearch({ executive_titles: TitleTypes, search_executive : TitleTypes[index].titles.length > 0}, formName));
    };

    const handleIncludeExcludeClick = (exclude, index) => {
        let TitleTypes = executive_titles.slice();
        TitleTypes[index].exclude = !exclude;
        dispatch(mergeSearch({ executive_titles: TitleTypes }, formName));
    };

    const handleDeleteButtonClick = (index) => {
        let TitleTypes = executive_titles.slice();
        TitleTypes.splice(index, 1);
        dispatch(mergeSearch({ executive_titles: TitleTypes }, formName));
    };

    const handleAddTitleClause = () => {
        let TitleTypes = executive_titles.slice();
        TitleTypes.push({
            exclude: false,
            executive_titles_bool: 'or',
            titles: []
        });
        dispatch(mergeSearch({ executive_titles: TitleTypes }, formName));
    };

    return (
        <Col lg={13}>
            {executive_titles.map((TitleTypeClause, index) => (
                <Card className="my-1 license-groups-card-border" key={index}>
                    <CardHeader className="bg-light">
                        <a className="link"
                           onClick={() => handleIncludeExcludeClick(TitleTypeClause.exclude, index)}
                        >
                            {TitleTypeClause.exclude ? 'Exclude' : 'Include'}
                        </a>
                        &nbsp;records matching these executives:

                        {executive_titles.length > 1 &&
                        <button
                            onClick={() => handleDeleteButtonClick(index)}
                            type="button"
                            className="close"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        }
                    </CardHeader>
                    <CardBody className="pt-1">
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Executive(s)</Label>
                                    <TitleTypes name="titles" multi={true}
                                                  value={TitleTypeClause.titles}
                                                  onChange={(event) => onFieldChange(event, index)} />

                                    {TitleTypeClause.titles.length > 1 &&
                                    <div className="form-inline flex-row-reverse pt-1">
                                        <p>
                                            Search for customers has &nbsp;
                                            <Input type="select"
                                                   name="executive_titles_bool"
                                                   bsSize="sm"
                                                   value={TitleTypeClause.executive_titles_bool}
                                                   onChange={(event) => onFieldChange(event, index)}>
                                                <option value="and">all of these executives</option>
                                                <option value="or">any of these executives</option>
                                            </Input>
                                        </p>
                                    </div>
                                    }
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            ))}
            {canAddAnotherTitleClause && (
                <Button
                    className="p-0 ml-3"
                    color="link"
                    onClick={handleAddTitleClause}>
                    + Add another Executive clause
                </Button>
            )}
        </Col>
    );
};

export default IndividualTitleSelect;